import { CreateLinkProps, useLocation } from '@tanstack/react-router';
import React, { ReactElement, useCallback, useContext, useEffect, useState } from 'react';

type BaseNavigationAction = {
  name: string;
  isMain?: boolean;
  icon: ReactElement;
};
export type RouterNavigationAction = {
  type: 'router';
  linkProps: CreateLinkProps;
} & BaseNavigationAction;

export type CallbackNavigationAction = {
  type: 'callback';
  callback: () => void;
} & BaseNavigationAction;

export type NavigationAction = RouterNavigationAction | CallbackNavigationAction;

export interface NavigationContextState {
  currentRoute?: string;
  mainAction?: NavigationAction;
  secondaryActions?: NavigationAction[];
}

export interface INavigationContext extends NavigationContextState {
  setActions: (actions: NavigationAction[]) => void;
}

export const NavigationContext = React.createContext<INavigationContext>({
  setActions: () => {
    return;
  }
});

export const NavigationContextProvider: React.FC<{ children?: React.ReactNode }> = ({
  children
}) => {
  const { pathname } = useLocation();

  const [navigationContextState, setNavigationContextState] = useState<NavigationContextState>({
    currentRoute: pathname
  });

  useEffect(() => {
    setNavigationContextState(state => {
      if (pathname !== state.currentRoute) {
        return {
          currentRoute: pathname,
          mainAction: undefined,
          secondaryActions: undefined
        };
      }
      return state;
    });
  }, [pathname]);

  const setActionsCallback = useCallback((actions: NavigationAction[]) => {
    setNavigationContextState(state => ({
      ...state,
      mainAction: actions.find(action => action.isMain),
      secondaryActions: actions.filter(action => !action.isMain)
    }));
  }, []);

  const context: INavigationContext = {
    ...navigationContextState,
    setActions: setActionsCallback
  };

  return <NavigationContext.Provider value={context}>{children}</NavigationContext.Provider>;
};

export const useNavigationContext = () => {
  const context = useContext(NavigationContext);
  if (context) return context;

  throw Error('Navigation Context Provider was not registered.');
};
