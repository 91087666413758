import gql from 'graphql-tag';
import { constants } from '../Constants';
import { DATA_TYPE_VALUE_FRAGMENT, DATA_TYPE_VALUE_FRAGMENT_NAME } from './DataTypeValueFragment';
import { INPUT_FIELD_FRAGMENT, INPUT_FIELD_FRAGMENT_NAME } from './InputFieldFragment';

export const DATA_POINT_FIELDS_FRAGMENT_NAME = 'DataPointFields';
export const DATA_POINT_FIELDS_FRAGMENT = gql`
  ${DATA_TYPE_VALUE_FRAGMENT}
  ${INPUT_FIELD_FRAGMENT}
  fragment ${DATA_POINT_FIELDS_FRAGMENT_NAME}  on ${constants.FormattedDataPointTypename} {
    id
    sourceDataPointId
    subjectId
    dataPointItems: items {
      key
      value {
        ...${DATA_TYPE_VALUE_FRAGMENT_NAME}
      }
      displayValue
    }
    actions {
      name
      key
      type

      ... on DisplayAction {
        isPrimary
        iFrame {
          url
          sendToken
        }
      }

      ... on PerformAction {
        description
        inputFields {
          ...${INPUT_FIELD_FRAGMENT_NAME}
        }
      }
    }
  }
`;
