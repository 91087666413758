import { DataType, FilterableField } from '@aireframe/shared-types';
import AddIcon from '@mui/icons-material/Add';
import { Button, ButtonGroup, Divider, Grid, IconButton, Tooltip, Typography } from '@mui/material';
import { useEffect, useMemo, useRef, useState } from 'react';
import { PopupMenu } from '../PopupMenu';
import { FieldsFilter } from './FieldsFilter';
import FilterListIcon from '@mui/icons-material/FilterAlt';
import ActiveFilter from './ActiveFilter';
import { createFilter } from './Filter.func';
import sortBy from 'lodash-es/sortBy';

type Props = {
  title?: string;
  noFiltersText?: string;
  fields: FilterableField[];
  value: FieldsFilter;
  onChange: (newValue: FieldsFilter) => void;
};

export const FiltersList = ({
  fields,
  value,
  onChange,
  title = 'Filter',
  noFiltersText = 'Add a filter to get started.'
}: Props) => {
  const [showOptions, setShowOptions] = useState(false);
  const [nextFilter, setNextFilter] = useState(value.clone());
  const anchorRef = useRef<HTMLButtonElement>(null);

  useEffect(() => {
    setNextFilter(value.clone());
  }, [value]);

  const newFilterOptions = useMemo(() => {
    return fields.filter(
      field => !(field.dataType === DataType.BOOLEAN && nextFilter.hasFieldFilter(field.key))
    );
  }, [fields, nextFilter]);

  const activeFilters = useMemo(() => {
    return fields.flatMap(field => {
      const filtersForKey = nextFilter.getFiltersByFieldKey(field.key);
      if (filtersForKey.XAND.length > 0) {
        return filtersForKey.XAND.map(f => ({ ...field, id: f.id, filters: [f] }));
      }

      if (filtersForKey.OR.length > 0) {
        return [
          {
            ...field,
            id: Math.min(...filtersForKey.OR.map(f => f.id)),
            filters: filtersForKey.OR
          }
        ];
      }

      return [];
    });
  }, [fields, nextFilter]);

  return (
    <>
      {showOptions && (
        <PopupMenu
          onClose={() => setShowOptions(false)}
          title="Add"
          showTitle={false}
          popoverProps={{ anchorEl: anchorRef.current }}>
          <ButtonGroup sx={{ p: 1 }} orientation="vertical" variant="outlined">
            {newFilterOptions.map(field => {
              const multiVisualisationDisabled = activeFilters.some(
                x => x.visualisationId !== field.visualisationId
              );
              return (
                <Tooltip
                  key={field.key}
                  title={
                    multiVisualisationDisabled
                      ? 'Filtering and sorting across visualisations is currently disabled'
                      : ''
                  }
                  slotProps={{
                    popper: {
                      modifiers: [
                        {
                          name: 'offset',
                          options: {
                            offset: [0, -14]
                          }
                        }
                      ]
                    }
                  }}>
                  <span>
                    <Button
                      fullWidth
                      disabled={multiVisualisationDisabled}
                      style={{ textTransform: 'none' }}
                      onClick={() => {
                        nextFilter.andAny(
                          createFilter(nextFilter.filterCount() + 1, field.key, field.dataType)
                        );
                        onChange(nextFilter);
                        setShowOptions(false);
                      }}>
                      {field.title}
                    </Button>
                  </span>
                </Tooltip>
              );
            })}
          </ButtonGroup>
        </PopupMenu>
      )}
      <Grid container spacing={1} direction="column" sx={{ px: 1, pb: 1 }}>
        <Grid
          container
          item
          style={{ position: 'sticky' }}
          direction="column"
          spacing={2}
          sx={{ mb: 1 }}>
          <Grid
            container
            item
            style={{ position: 'relative' }}
            direction="row"
            justifyContent="center">
            <Grid display="flex" item alignItems="center">
              <FilterListIcon
                style={{ color: 'rgba(0, 0, 0, 0.54)', fontSize: '120%' }}
                sx={{ mr: 1 }}
              />
              <Typography variant="h6">{title}</Typography>
            </Grid>

            <Tooltip title="Add Filter">
              <IconButton
                onClick={() => setShowOptions(true)}
                ref={anchorRef}
                aria-label="Add Filter"
                style={{ position: 'absolute', right: 0, top: '30%' }}>
                <AddIcon />
              </IconButton>
            </Tooltip>
          </Grid>

          <Grid item>
            <Divider />
          </Grid>
        </Grid>

        {activeFilters.length === 0 && (
          <Grid item>
            <Typography style={{ textAlign: 'center' }}>{noFiltersText}</Typography>
          </Grid>
        )}

        {sortBy(activeFilters, f => f.id).map(field => (
          <Grid item key={field.id}>
            <ActiveFilter
              filterKey={field.key}
              title={field.title}
              filters={field.filters}
              filterState={nextFilter}
              setFilterState={onChange}
            />
            <Divider />
          </Grid>
        ))}
      </Grid>
    </>
  );
};
