import FilterListIcon from '@mui/icons-material/FilterAlt';
import ErrorIcon from '@mui/icons-material/Error';
import { Chip, IconButton, IconButtonProps, Tooltip } from '@mui/material';
import React, { AriaAttributes, CSSProperties } from 'react';

const OpenFilterButton = React.forwardRef<
  HTMLButtonElement,
  IconButtonProps & { tooltipText?: string }
>((props, ref) => {
  const { children, tooltipText, ...rest } = props;
  return (
    <Tooltip title={tooltipText ?? props['aria-label'] ?? 'Filter'}>
      <IconButton aria-label="Filter" ref={ref} {...rest} size="small">
        {children}
      </IconButton>
    </Tooltip>
  );
});
OpenFilterButton.displayName = 'OpenFilterButton';

const FilterIconButton = React.forwardRef<
  HTMLButtonElement,
  {
    filterActive: boolean;
    invalidFilter?: boolean;
    style?: CSSProperties;
    onClick: () => void;
  } & AriaAttributes
>(({ filterActive, invalidFilter = false, style, onClick, ...ariaProps }, ref) => {
  if (!filterActive) {
    return (
      <OpenFilterButton {...ariaProps} color="default" style={style} onClick={onClick} ref={ref}>
        <FilterListIcon />
      </OpenFilterButton>
    );
  }

  return (
    <Chip
      icon={
        <OpenFilterButton
          {...ariaProps}
          ref={ref}
          tooltipText={
            invalidFilter ? 'Filter failed to resolve. No results will be shown.' : undefined
          }>
          {invalidFilter ? <ErrorIcon /> : <FilterListIcon />}
        </OpenFilterButton>
      }
      label={invalidFilter ? 'Invalid filter' : 'Filter Active'}
      clickable
      onClick={() => onClick()}
      color={invalidFilter ? 'error' : 'primary'}
      style={style}
      sx={{ fontWeight: t => t.typography.fontWeightBold }}
    />
  );
});

FilterIconButton.displayName = 'FilterIconButton';
export { FilterIconButton };
