import {
  BarSeriesOption,
  ECharts,
  EChartsOption,
  LineSeriesOption,
  MarkLineComponentOption,
  ScatterSeriesOption,
  SeriesOption,
  XAXisComponentOption,
  YAXisComponentOption
} from 'echarts';
import { groupBy } from 'lodash-es';

import {
  Axis,
  AxisType,
  ChartType,
  DataType,
  isGraphChartDisplayOptions,
  RenderedSeries,
  Series,
  VisualisationRenderType
} from '@aireframe/shared-types';
import { CallbackDataParams } from 'echarts/types/dist/shared';
import {
  ChartProps,
  getFormatterForSeries,
  getXAxisDomain,
  parseValue,
  useEchartsDataset
} from '../DataVisualisationHelpers';
import { isDateTimeSeries } from '../Utils';
import { useVisualisationContext } from '../VisualisationContext';
import { ReactECharts } from './Echart-React';
import { DataSetItemValue, useChartColours } from './useChartColours';
import { isDisplayAction } from '@aireframe/graphql';

function createMarkLines(
  series: Series,
  axis: Extract<AxisType, AxisType.X | AxisType.Y>
): MarkLineComponentOption {
  return {
    silent: true,
    symbol: 'none',
    data: series.referenceValues?.map(referenceValue => ({
      name: referenceValue.label,
      [axis === AxisType.X ? 'xAxis' : 'yAxis']: parseValue(referenceValue.value, series.dataType),
      label: {
        position: 'insideStartTop',
        formatter: referenceValue.label
      }
    }))
  };
}

function createXAxis(
  axesByType: { [index: string]: Axis[] },
  series: Series[]
): { axis: XAXisComponentOption; series: Series } {
  if (!axesByType[AxisType.X] || axesByType[AxisType.X].length === 0) {
    throw new Error('Chart has not been configured correctly - no X axis');
  }

  if (axesByType[AxisType.X].length > 1) {
    throw new Error('Chart has not been configured correctly - more than 1 X Axis');
  }

  const xAxisDefinition = axesByType[AxisType.X][0];
  const xAxisSeries = series.find(series => xAxisDefinition.series.some(s => s.key === series.key));

  if (xAxisSeries === undefined) {
    throw new Error('xAxis series is undefined');
  }

  const baseAxisOptions: XAXisComponentOption = {
    nameLocation: 'middle',
    nameGap: 25,
    name: xAxisDefinition.label,
    axisLabel: {
      formatter: getFormatterForSeries(xAxisSeries),
      hideOverlap: false
    }
  };

  if (xAxisSeries.dataType === DataType.STRING) {
    return {
      axis: {
        ...baseAxisOptions,
        type: 'category',
        axisLabel: {
          formatter: getFormatterForSeries(xAxisSeries),
          hideOverlap: false,
          interval: 0
        }
      },
      series: xAxisSeries
    };
  }

  const { min: xAxisMin, max: xAxisMax } = getXAxisDomain([xAxisSeries]);

  if (isDateTimeSeries(xAxisSeries)) {
    return {
      axis: {
        ...baseAxisOptions,
        type: 'time',
        min: xAxisMin,
        max: xAxisMax
      },
      series: xAxisSeries
    };
  }

  return {
    axis: {
      ...baseAxisOptions,
      type: 'value',
      min: xAxisMin,
      max: xAxisMax
    },
    series: xAxisSeries
  };
}

function createSeries(
  definition: VisualisationRenderType,
  series: RenderedSeries,
  xAxisSeries: Series,
  colourMapper: ReturnType<typeof useChartColours>['colourMapper']
): SeriesOption {
  const baseOptions: SeriesOption = {
    name: series.renderedName,
    encode: {
      x: xAxisSeries?.key,
      y: series.key
    },
    markLine: createMarkLines(series, AxisType.Y),
    colorBy: definition.isCategorical ? 'data' : 'series'
  };

  const colouringFunction = (params: CallbackDataParams) => {
    const data = params.data as { [seriesKey: string]: DataSetItemValue };
    const colouringSeries = definition.isCategorical ? xAxisSeries : series;
    if (!(colouringSeries.key in data)) {
      throw new Error(`Data for series ${colouringSeries.key} not found`);
    }

    return colourMapper(colouringSeries, data[colouringSeries.key], params.dataIndex);
  };

  switch (series.chartType) {
    case ChartType.BAR:
    case ChartType.STACKED_BAR:
      return {
        ...baseOptions,
        type: 'bar',
        stack: series.chartType === ChartType.STACKED_BAR ? 'stack' : undefined,
        barMaxWidth: '20%',
        itemStyle: {
          color: colouringFunction
        }
      } as BarSeriesOption;
    case ChartType.LINE:
      return {
        ...baseOptions,
        type: 'line',
        itemStyle: {
          color: colouringFunction
        }
      } as LineSeriesOption;
    case ChartType.SCATTER:
      return {
        ...baseOptions,
        type: 'scatter',
        itemStyle: {
          color: colouringFunction
        }
      } as ScatterSeriesOption;
    default:
      throw new Error('Unknown graph type');
  }
}

const Graph: React.FC<ChartProps> = ({ data, height = 300, onActionClicked }) => {
  const { definition } = useVisualisationContext();
  const dataset = useEchartsDataset(data);
  const axesByType = groupBy(definition.axes, axis => axis.type);

  const { colourMapper } = useChartColours(
    isGraphChartDisplayOptions(definition.displayOptions) ? definition.displayOptions : null,
    dataset
  );
  const { axis: xAxis, series: xAxisSeries } = createXAxis(axesByType, definition.series);
  const yAxes: YAXisComponentOption[] = axesByType[AxisType.Y].map(axis => ({
    type: 'value',
    name: axis.label,
    nameLocation: 'middle',
    nameGap: 35
  }));

  const chartOptions: EChartsOption = {
    tooltip: {
      trigger: 'item',
      axisPointer: { type: 'cross' },
      formatter: params => {
        const { dimensionNames: seriesNames, dataIndex } = params as {
          dataIndex: number;
          dimensionNames: Array<string>;
        };

        return seriesNames.reduce((val, seriesId) => {
          const displayValue =
            data[dataIndex as number].data[seriesId as string]?.displayValue ?? '';
          return (
            val +
            `${definition.series.find(s => s.key === seriesId)?.renderedName}: ${displayValue} <br />`
          );
        }, '');
      }
    },
    legend: !definition.isCategorical
      ? {
          data: definition.series.map(x => x.renderedName)
        }
      : undefined,
    xAxis,
    yAxis: yAxes,
    series: definition.series
      .filter(
        series =>
          !series.hidden &&
          axesByType[AxisType.Y].some(axis => axis.series.some(s => s.key === series.key))
      )
      .map(series => createSeries(definition, series, xAxisSeries, colourMapper)),
    dataset
  };

  const getPrimaryAction = (index: number) =>
    data[index]?.actions?.find(action => isDisplayAction(action) && action.isPrimary);

  const onGraphElementClick = (index: number) => {
    const primaryAction = getPrimaryAction(index);
    if (primaryAction && onActionClicked) onActionClicked(data[index], primaryAction);
  };

  const onGraphElementHover = (index: number, chart: ECharts) => {
    const hasPrimaryAction = !!getPrimaryAction(index);
    if (hasPrimaryAction) chart.getZr().setCursorStyle('pointer');
    else chart.getZr().setCursorStyle('default');
  };

  const getLabelWidth = (width: number) => {
    if (!definition.series.some(s => s.chartType === ChartType.BAR)) {
      return;
    }

    return (width / data.length) * 0.8;
  };

  return (
    <ReactECharts
      height={height}
      option={chartOptions}
      onClick={({ dataIndex }) => onGraphElementClick(dataIndex)}
      onHover={({ dataIndex }, chart) => onGraphElementHover(dataIndex, chart)}
      getLabelWidth={getLabelWidth}
      truncateLabels
    />
  );
};

export default Graph;
