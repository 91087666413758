import { DataType, FieldFilter, StringComparator } from '@aireframe/shared-types';
import {
  FormControl,
  FormControlLabel,
  FormLabel,
  Grid,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  TextField,
  TextFieldProps
} from '@mui/material';
import FilterHeader from '../FilterHeader';
import { useState } from 'react';
import { LiquidEditor } from '../../Monaco';

type ValueMode = 'exact' | 'liquid';

const StringFilter = ({
  title,
  value: filter,
  onChange,
  onRemove,
  textFieldProps
}: {
  title: string;
  value: FieldFilter<DataType.STRING>;
  onChange: (value: FieldFilter<DataType.STRING>) => void;
  onRemove: () => void;
  textFieldProps?: Omit<TextFieldProps, 'type' | 'defaultValue' | 'onBlur'>;
}) => {
  const [valueMode, setValueMode] = useState<ValueMode>(
    filter.liquidExpression ? 'liquid' : 'exact'
  );

  const onValueModeChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const valueMode = event.target.value as ValueMode;
    if (valueMode === 'liquid') {
      onChange({
        ...filter,
        value: {
          ...filter.value,
          stringValue: null
        }
      });
    } else {
      onChange({
        ...filter,
        liquidExpression: null
      });
    }
    setValueMode(valueMode);
  };

  return (
    <Grid container direction="column" spacing={1} sx={{ p: 1 }}>
      <Grid item>
        <FilterHeader title={title} onClear={onRemove} />
      </Grid>
      <Grid item>
        <FormControl fullWidth>
          <Select
            inputProps={{ 'aria-label': 'Filter type' }}
            value={filter.comparator}
            onChange={e => onChange({ ...filter, comparator: e.target.value as StringComparator })}
            style={{ textAlign: 'left' }}>
            <MenuItem value={StringComparator.Contains}>Contains</MenuItem>
            <MenuItem value={StringComparator.Equals}>Equals</MenuItem>
            <MenuItem value={StringComparator.StartsWith}>Starts with</MenuItem>
            <MenuItem value={StringComparator.EndsWith}>Ends With</MenuItem>
            <MenuItem value={StringComparator.NotContains}>Does Not Contain</MenuItem>
            <MenuItem value={StringComparator.NotEquals}>Does Not Equal</MenuItem>
            <MenuItem value={StringComparator.NotStartsWith}>Does Not Start With</MenuItem>
            <MenuItem value={StringComparator.NotEndsWith}>Does Not End With</MenuItem>
          </Select>
        </FormControl>
      </Grid>
      <Grid item>
        <FormControl>
          <FormLabel id="value-mode-buttons-group-label">Value mode</FormLabel>
          <RadioGroup
            aria-labelledby="value-mode-buttons-group-label"
            name="value-mode"
            row
            value={valueMode}
            onChange={onValueModeChange}>
            <FormControlLabel value="exact" control={<Radio />} label="Exact" />
            <FormControlLabel value="liquid" control={<Radio />} label="Liquid" />
          </RadioGroup>
        </FormControl>
      </Grid>
      <Grid item>
        {valueMode === 'exact' && (
          <TextField
            label={title}
            {...textFieldProps}
            type="text"
            defaultValue={filter.value.stringValue}
            onBlur={event =>
              event.target.value === ''
                ? onChange({
                    ...filter,
                    value: { dataType: DataType.STRING, stringValue: null }
                  })
                : onChange({
                    ...filter,
                    value: { dataType: DataType.STRING, stringValue: event.target.value }
                  })
            }
          />
        )}
        {valueMode === 'liquid' && (
          <LiquidEditor
            label="Liquid Expression"
            {...textFieldProps}
            type="text"
            fullWidth
            value={filter.liquidExpression ?? undefined}
            onChange={value =>
              !value || value === ''
                ? onChange({ ...filter, liquidExpression: null })
                : onChange({
                    ...filter,
                    liquidExpression: value
                  })
            }
          />
        )}
      </Grid>
    </Grid>
  );
};

export default StringFilter;
