import { TextFieldProps } from '@mui/material';
import { DateTimePicker } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import dayjs, { Dayjs } from 'dayjs';
import 'dayjs/locale/en-gb';

export const DateTimeFilter = ({
  value,
  onChange,
  ...props
}: {
  value: string | null;
  onChange: (value: string | null) => void;
} & Omit<TextFieldProps, 'type' | 'defaultValue' | 'onBlur' | 'value' | 'onChange'>) => {
  const handleChangeAbsolute = (newValue: Dayjs | null) => {
    if (newValue !== null && newValue.isValid()) {
      onChange(newValue.toISOString());
    } else {
      onChange(null);
    }
  };

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="en-gb">
      <DateTimePicker
        slotProps={{
          textField: {
            ...props
          }
        }}
        label="Value"
        sx={{ width: '100%' }}
        defaultValue={value ? dayjs(value) : null}
        onChange={handleChangeAbsolute}
      />
    </LocalizationProvider>
  );
};
