import gql from 'graphql-tag';
import { SubTenantStructuralEntity } from './StructuralEntity';
import {
  CORE_STRUCTURAL_ENTITY_FIELDS,
  CORE_STRUCTURAL_ENTITY_FIELDS_FRAGMENT_NAME
} from '../Fragments';
import { ValueInput } from '../../api.gen';

export interface StructuralEntityInput {
  key: string;
  displayName: string;
  structureTypeKey: string;
  parentKey: string | null;
  customFieldValues: Array<{
    key: string;
    value: ValueInput;
  }>;
}

export interface CreateStructuralEntityMutationVars {
  structuralEntity: StructuralEntityInput;
}

export interface CreateStructuralEntityMutationData {
  createStructuralEntity: SubTenantStructuralEntity;
}

export const CREATE_STRUCTURAL_ENTITY_MUTATION_NAME = 'CreateStructuralEntity';
export const CREATE_STRUCTURAL_ENTITY_MUTATION = gql`
  ${CORE_STRUCTURAL_ENTITY_FIELDS}

  mutation ${CREATE_STRUCTURAL_ENTITY_MUTATION_NAME}($structuralEntity: StructuralEntityInput!) {
    createStructuralEntity(input: $structuralEntity) {
      ...${CORE_STRUCTURAL_ENTITY_FIELDS_FRAGMENT_NAME}
    }
  }
`;
