import { UserIdentifierType } from './DataType';
import { NumericComparator, StringComparator } from './Filters';
import * as zod from 'zod';

const liquidExpression = zod.object({
  liquidExpression: zod.string().nullable()
});

const fieldFilterInputSchema = zod.object({
  fieldKey: zod.string(),
  visualisationId: zod.string().uuid().nullable().optional(),
  filter: zod.union([
    zod
      .object({
        booleanValue: zod.union([zod.boolean(), zod.null()])
      })
      .extend(liquidExpression.shape),
    zod
      .object({
        stringValue: zod.union([zod.string(), zod.null()]),
        stringComparator: zod.nativeEnum(StringComparator)
      })
      .extend(liquidExpression.shape),
    zod
      .object({
        integerValue: zod.union([zod.number(), zod.null()]),
        numericComparator: zod.nativeEnum(NumericComparator)
      })
      .extend(liquidExpression.shape),
    zod
      .object({
        decimalValue: zod.union([zod.number(), zod.null()]),
        numericComparator: zod.nativeEnum(NumericComparator)
      })
      .extend(liquidExpression.shape),
    zod
      .object({
        timeValue: zod.union([zod.string(), zod.null()]),
        numericComparator: zod.nativeEnum(NumericComparator)
      })
      .extend(liquidExpression.shape),
    zod
      .object({
        dateValue: zod.union([zod.string(), zod.null()]),
        numericComparator: zod.nativeEnum(NumericComparator)
      })
      .extend(liquidExpression.shape),
    zod
      .object({
        dateTimeValue: zod.union([zod.string(), zod.null()]),
        numericComparator: zod.nativeEnum(NumericComparator)
      })
      .extend(liquidExpression.shape),
    zod
      .object({
        userIdentifierValue: zod.union([
          zod.object({
            identifier: zod.string(),
            userIdentifierType: zod.nativeEnum(UserIdentifierType)
          }),
          zod.null()
        ])
      })
      .extend(liquidExpression.shape)
  ])
});

export const dataPointsFilterInputSchema = zod.object({
  and: zod.array(
    zod.object({
      or: zod.array(fieldFilterInputSchema)
    })
  )
});

export type FieldFilterInput = zod.infer<typeof fieldFilterInputSchema>;
export type DataPointsFilter = zod.infer<typeof dataPointsFilterInputSchema>;
