import { appendPath, isAbsoluteURI } from '@aireframe/shared-types';
import { Button, Grid, useMediaQuery } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import {
  forwardRef,
  useCallback,
  useEffect,
  useImperativeHandle,
  useMemo,
  useRef,
  useState
} from 'react';
import { useAppContext } from '../AppContext';
import { useAuthentication } from '../Authentication';
import Loading from '../Loading/Loading';
import { SubjectBanner, ViewSubjectRecordButton, useSubjectContext } from '../Subject';

type Props = {
  url: string;
  onClosed: () => void;
  onReceiveMessage?: (event: MessageEvent) => void;
  showSubjectLink?: boolean;
  sendToken?: boolean;
  isInline?: boolean;
  showSubjectBanner?: boolean;
};

export type IFrameWrapperRef = {
  postMessage: (data: unknown) => void;
};

const SubjectLink: React.FC<{
  onClosed: () => void;
}> = ({ onClosed }) => {
  const { subject } = useSubjectContext();

  return (
    <div style={{ position: 'absolute', top: 15, right: 5 }}>
      <ViewSubjectRecordButton subject={subject} buttonProps={{ onClick: onClosed }} />
    </div>
  );
};

const IFrameWrapper = forwardRef<IFrameWrapperRef, Props>(
  (
    {
      url,
      onClosed,
      onReceiveMessage,
      showSubjectLink = false,
      sendToken,
      isInline = true,
      showSubjectBanner = true
    },
    ref
  ) => {
    const { apiHost } = useAppContext();
    const [iframeLoading, setiFrameLoading] = useState(true);
    const { getBearerToken, signOut } = useAuthentication();
    const [bearerToken, setBearerToken] = useState<string | null>(null);
    const theme = useTheme();
    const isSmallScreen = useMediaQuery(theme.breakpoints.down('md'));

    // Update iframe height to fit remaining space within sidebar and if banner or sidebar dimensions change
    const [iframeHeight, setIframeHeight] = useState<number | string>('95%');
    const iframeRef = useRef<HTMLIFrameElement>(null);
    const sidebarRef = useRef<HTMLDivElement | null>(null);
    const bannerRef = useRef<HTMLDivElement | null>(null);

    useEffect(() => {
      if (sendToken) {
        getBearerToken().then(token => {
          if (!token) {
            signOut();
          }
          setBearerToken(token);
        });
      }
    }, [getBearerToken, signOut, sendToken]);

    const completeUrl = useMemo(() => {
      const builtUrl = new URL(isAbsoluteURI(url) ? url : appendPath(apiHost, url));
      if (sendToken) {
        builtUrl.searchParams.append('jwt', bearerToken ?? '');
      }
      return builtUrl;
    }, [url, bearerToken, sendToken, apiHost]);

    useEffect(() => {
      const addWindowMessageHandler = (w: Window | null) => {
        const handleIFrameEvents = (e: MessageEvent) => {
          if (onReceiveMessage) {
            onReceiveMessage(e);
          }

          switch (e.data.eventType) {
            case 'form-submitted':
            case 'form-closed':
            case 'form-discarded': {
              onClosed();
              break;
            }
            default:
              break;
          }
        };

        w?.addEventListener('message', handleIFrameEvents);
        return () => {
          w?.removeEventListener('message', handleIFrameEvents);
        };
      };

      if (isSmallScreen) {
        onClosed();
      } else {
        return addWindowMessageHandler(window);
      }
    }, [isSmallScreen, completeUrl, onClosed, onReceiveMessage]);

    const resizeIframe = useCallback(
      (sidebar: Element) => {
        const newFrameHeight = sidebar.clientHeight - (bannerRef.current?.clientHeight ?? 0) - 30;
        if (iframeHeight !== newFrameHeight) {
          setIframeHeight(newFrameHeight);
        }
      },
      [iframeHeight]
    );

    useEffect(() => {
      const sidebarObserver = new ResizeObserver((entries: Array<ResizeObserverEntry>) => {
        for (const entry of entries) {
          resizeIframe(entry.target);
        }
      });

      if (sidebarRef.current) sidebarObserver.observe(sidebarRef.current);
    }, [sidebarRef, resizeIframe]);

    useEffect(() => {
      const bannerObserver = new ResizeObserver((entries: Array<ResizeObserverEntry>) => {
        for (const entry of entries) {
          resizeIframe(entry.target.parentElement as HTMLDivElement);
        }
      });

      if (bannerRef.current) bannerObserver.observe(bannerRef.current);

      return () => bannerObserver.disconnect();
    }, [bannerRef, resizeIframe]);

    useImperativeHandle(ref, () => ({
      postMessage(data: unknown) {
        iframeRef.current?.contentWindow?.postMessage(data, '*');
      }
    }));

    const onOpenInNewTab = () => {
      window.open(url, '_blank');
    };

    if (sendToken && !bearerToken) {
      return <Loading />;
    }

    if (isSmallScreen) {
      return (
        <Button sx={{ m: 2 }} onClick={onOpenInNewTab}>
          Open in new tab
        </Button>
      );
    } else if (isInline) {
      return (
        <div style={{ height: '100%' }} ref={sidebarRef}>
          <Grid
            item
            xs={12}
            container
            spacing={1}
            ref={bannerRef}
            sx={{ padding: theme => theme.spacing(0, 1) }}>
            {showSubjectLink && <SubjectLink onClosed={onClosed} />}
            {showSubjectBanner && (
              <Grid item xs={12}>
                <SubjectBanner rowItems={2} initiallyExpanded={false} />
              </Grid>
            )}
          </Grid>
          {iframeLoading && <Loading />}
          <iframe
            src={completeUrl.href}
            title="iframe"
            style={{
              width: '1px',
              minWidth: '100%',
              border: 'none',
              height: iframeHeight,
              display: 'none'
            }}
            ref={iframeRef}
            onLoad={e => {
              setiFrameLoading(false);
              e.currentTarget.style.display = 'inline';
            }}
          />
        </div>
      );
    } else {
      return (
        <>
          {iframeLoading && <Loading />}
          <iframe
            src={completeUrl.href}
            title="iframe"
            style={{
              width: '1px',
              minWidth: '100%',
              border: 'none',
              minHeight: '76vh',
              display: 'none'
            }}
            ref={iframeRef}
            onLoad={e => {
              setiFrameLoading(false);
              e.currentTarget.style.display = 'inline';
            }}
          />
        </>
      );
    }
  }
);
IFrameWrapper.displayName = 'IFrameWrapper';

export default IFrameWrapper;
