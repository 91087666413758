import { DataType, FieldFilter } from '@aireframe/shared-types';
import {
  FormControl,
  FormControlLabel,
  FormLabel,
  Grid,
  Radio,
  RadioGroup,
  TextFieldProps
} from '@mui/material';
import UserIdentifierLookUpField from '../../CustomFields/UserIdentifier/UserIdentifierLookupField';
import FilterHeader from '../FilterHeader';
import { useState } from 'react';

const currentUserExpression = '{{ currentUser }}';

export const UserIdentifierFilter = ({
  title,
  value: filter,
  onChange,
  onRemove,
  textFieldProps
}: {
  title: string;
  value: FieldFilter<DataType.USERIDENTIFIER>;
  onChange: (value: FieldFilter<DataType.USERIDENTIFIER>) => void;
  onRemove: () => void;
  textFieldProps?: Omit<TextFieldProps, 'type' | 'defaultValue' | 'onBlur'>;
}) => {
  const [valueMode, setValueMode] = useState<'current' | 'specific'>(
    filter.liquidExpression === currentUserExpression ? 'current' : 'specific'
  );

  const onValueModeChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value as 'current' | 'specific';

    if (value === 'current') {
      onChange({
        ...filter,
        value: {
          dataType: DataType.USERIDENTIFIER,
          userIdentifierValue: null
        },
        liquidExpression: currentUserExpression
      });
    } else {
      onChange({
        ...filter,
        value: {
          dataType: DataType.USERIDENTIFIER,
          userIdentifierValue: null
        },
        liquidExpression: null
      });
    }
    setValueMode(value);
  };

  return (
    <Grid container direction="column" spacing={1} sx={{ p: 1 }}>
      <Grid item>
        <FilterHeader title={title} onClear={onRemove} />
      </Grid>
      <Grid item>
        <FormControl>
          <FormLabel id="user-selection-mode-label">Mode</FormLabel>
          <RadioGroup
            aria-labelledby="user-selection-mode-label"
            name="user-selection-mode-radio-buttons-group"
            row
            value={valueMode}
            onChange={onValueModeChange}>
            <FormControlLabel value="specific" control={<Radio />} label="Specific user" />
            <FormControlLabel value="current" control={<Radio />} label="Logged in user" />
          </RadioGroup>
        </FormControl>
      </Grid>
      <Grid item>
        {valueMode === 'specific' && (
          <UserIdentifierLookUpField
            autoCompleteProps={{
              fullWidth: true,
              label: title,
              ...textFieldProps
            }}
            onChange={userIdentifier =>
              onChange({
                ...filter,
                value: {
                  dataType: DataType.USERIDENTIFIER,
                  userIdentifierValue: userIdentifier ?? null
                }
              })
            }
            value={filter.value.userIdentifierValue}
          />
        )}
      </Grid>
    </Grid>
  );
};

export default UserIdentifierFilter;
