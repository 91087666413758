import {
  assertUnreachable,
  DataPointsFilter,
  DataType,
  FieldFilter,
  FieldFilterInput,
  isBooleanFieldFilter,
  isDateFieldFilter,
  isDateTimeFieldFilter,
  isDecimalFieldFilter,
  isIntegerFieldFilter,
  isStringFieldFilter,
  isTimeFieldFilter,
  isUserIdentifierFieldFilter
} from '@aireframe/shared-types';
import { isEqual } from 'lodash-es';

export class FieldsFilter {
  private AndFilters: ReadonlyArray<{ or: ReadonlyArray<FieldFilter> }>;

  public constructor(andFilters: ReadonlyArray<{ or: ReadonlyArray<FieldFilter> }> = []) {
    this.AndFilters = andFilters;
  }

  public and(filter: FieldFilter): void {
    this.removeFilterById(filter.id);
    this.AndFilters = [...this.AndFilters, { or: [filter] }];
  }

  public andAll(filters: Array<FieldFilter>): void {
    if (filters.length === 0) return;

    filters.forEach(filter => this.removeFilterById(filter.id));
    this.AndFilters = [...this.AndFilters, ...filters.map(filter => ({ or: [filter] }))];
  }

  public andAny(filters: Array<FieldFilter>): void {
    if (filters.length === 0) return;

    filters.forEach(filter => this.removeFilterById(filter.id));
    this.AndFilters = [...this.AndFilters, { or: filters }];
  }

  public filterCount(): number {
    return this.AndFilters.reduce((acc, and) => acc + and.or.length, 0);
  }

  public hasFilter(): boolean {
    return this.filterCount() > 0;
  }

  public removeFiltersByFieldKey(fieldKey: FieldFilter['key']): void {
    this.AndFilters = this.AndFilters.map(and => ({
      or: and.or.filter(or => or.key !== fieldKey)
    })).filter(and => and.or.length > 0);
  }

  public removeFilterById(filterId: FieldFilter['id']): void {
    this.AndFilters = this.AndFilters.map(and => ({
      or: and.or.filter(or => or.id !== filterId)
    })).filter(and => and.or.length > 0);
  }

  public getAllFilters(): ReadonlyArray<FieldFilter> {
    return this.AndFilters.flatMap(and => and.or);
  }

  public getFiltersByFieldKey(fieldKey: FieldFilter['key']): {
    XAND: ReadonlyArray<FieldFilter>;
    OR: ReadonlyArray<FieldFilter>;
  } {
    const XAND = this.AndFilters.filter(
      and => and.or.some(or => or.key === fieldKey) && and.or.length === 1
    ).map(and => and.or[0]);

    return {
      XAND,
      OR: this.AndFilters.flatMap(and =>
        and.or.filter(or => or.key === fieldKey && !XAND.some(filter => filter.id === or.id))
      )
    };
  }

  public hasFieldFilter(fieldKey: FieldFilter['key']): boolean {
    return this.AndFilters.some(and => and.or.some(or => or.key === fieldKey));
  }

  public toFilterInput(
    keyExtractor?: (key: string) => { visualisationId: string | null; key: string }
  ): DataPointsFilter | null {
    if (!this.hasFilter()) return null;

    return {
      and: this.AndFilters.map(and => ({
        or: and.or.map(or => {
          let base: { fieldKey: string; visualisationId?: string };

          if (keyExtractor) {
            const extractedKey = keyExtractor(or.key);
            if (extractedKey.visualisationId) {
              base = { fieldKey: extractedKey.key, visualisationId: extractedKey.visualisationId };
            } else {
              base = { fieldKey: extractedKey.key };
            }
          } else {
            base = { fieldKey: or.key };
          }

          if (isBooleanFieldFilter(or)) {
            return {
              ...base,
              filter: {
                booleanValue: or.value.booleanValue,
                liquidExpression: or.liquidExpression
              }
            };
          } else if (isStringFieldFilter(or)) {
            return {
              ...base,
              filter: {
                stringValue: or.value.stringValue,
                stringComparator: or.comparator,
                liquidExpression: or.liquidExpression
              }
            };
          } else if (isIntegerFieldFilter(or)) {
            return {
              ...base,
              filter: {
                integerValue: or.value.integerValue,
                numericComparator: or.comparator,
                liquidExpression: or.liquidExpression
              }
            };
          } else if (isDecimalFieldFilter(or)) {
            return {
              ...base,
              filter: {
                decimalValue: or.value.decimalValue,
                numericComparator: or.comparator,
                liquidExpression: or.liquidExpression
              }
            };
          } else if (isTimeFieldFilter(or)) {
            return {
              ...base,
              filter: {
                timeValue: or.value.timeValue,
                numericComparator: or.comparator,
                liquidExpression: or.liquidExpression
              }
            };
          } else if (isDateFieldFilter(or)) {
            return {
              ...base,
              filter: {
                dateValue: or.value.dateValue,
                numericComparator: or.comparator,
                liquidExpression: or.liquidExpression
              }
            };
          } else if (isDateTimeFieldFilter(or)) {
            return {
              ...base,
              filter: {
                dateTimeValue: or.value.dateTimeValue,
                numericComparator: or.comparator,
                liquidExpression: or.liquidExpression
              }
            };
          } else if (isUserIdentifierFieldFilter(or)) {
            return {
              ...base,
              filter: {
                userIdentifierValue: or.value.userIdentifierValue,
                liquidExpression: or.liquidExpression
              }
            };
          } else {
            return assertUnreachable(or);
          }
        })
      }))
    };
  }

  public clone(): FieldsFilter {
    return new FieldsFilter(this.AndFilters.map(and => ({ or: [...and.or] })));
  }

  public equals(other: FieldsFilter): boolean {
    return (
      this.AndFilters.every(and =>
        and.or.every(or =>
          other.AndFilters.some(otherAnd => otherAnd.or.some(otherOr => isEqual(otherOr, or)))
        )
      ) &&
      other.AndFilters.every(otherAnd =>
        otherAnd.or.every(otherOr =>
          this.AndFilters.some(and => and.or.some(or => isEqual(or, otherOr)))
        )
      )
    );
  }

  public static fromFilterInput(
    input: DataPointsFilter | null,
    keyMapper: (filter: FieldFilterInput) => string = filter => filter.fieldKey
  ): FieldsFilter {
    let id = 0;

    return new FieldsFilter(
      input?.and.map(and => ({
        or: and.or.map(or => {
          id++;

          const base = {
            id,
            key: keyMapper(or),
            liquidExpression: or.filter.liquidExpression
          };

          const { filter } = or;
          if ('booleanValue' in filter) {
            return {
              ...base,
              value: { booleanValue: filter.booleanValue, dataType: DataType.BOOLEAN }
            };
          } else if ('stringValue' in filter) {
            return {
              ...base,
              value: { stringValue: filter.stringValue, dataType: DataType.STRING },
              comparator: filter.stringComparator
            };
          } else if ('integerValue' in filter) {
            return {
              ...base,
              value: { integerValue: filter.integerValue, dataType: DataType.INTEGER },
              comparator: filter.numericComparator
            };
          } else if ('decimalValue' in filter) {
            return {
              ...base,
              value: { decimalValue: filter.decimalValue, dataType: DataType.DECIMAL },
              comparator: filter.numericComparator
            };
          } else if ('timeValue' in filter) {
            return {
              ...base,
              value: { timeValue: filter.timeValue, dataType: DataType.TIME },
              comparator: filter.numericComparator
            };
          } else if ('dateValue' in filter) {
            return {
              ...base,
              value: { dateValue: filter.dateValue, dataType: DataType.DATE },
              comparator: filter.numericComparator
            };
          } else if ('dateTimeValue' in filter) {
            return {
              ...base,
              value: { dateTimeValue: filter.dateTimeValue, dataType: DataType.DATETIME },
              comparator: filter.numericComparator
            };
          } else if ('userIdentifierValue' in filter) {
            return {
              ...base,
              value: {
                userIdentifierValue: filter.userIdentifierValue,
                dataType: DataType.USERIDENTIFIER
              }
            };
          } else {
            return assertUnreachable(filter);
          }
        })
      }))
    );
  }
}
