import { TextFieldProps } from '@mui/material';
import { TimePicker } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import dayjs, { Dayjs } from 'dayjs';
import 'dayjs/locale/en-gb';

export const TimeFilter = ({
  value,
  onChange,
  ...props
}: {
  value: string | null;
  onChange: (value: string | null) => void;
} & Omit<TextFieldProps, 'type' | 'defaultValue' | 'onBlur' | 'value' | 'onChange'>) => {
  const handleChangeAbsolute = (newValue: Dayjs | null) => {
    if (newValue !== null && newValue.isValid()) {
      onChange(newValue.format('HH:mm:ss'));
    } else {
      onChange(null);
    }
  };

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="en-gb">
      <TimePicker
        sx={{ width: '100%' }}
        label="Value"
        slotProps={{
          textField: {
            ...props
          }
        }}
        defaultValue={
          value ? dayjs(value, ['HH:mm:ss', 'H:mm:ss', 'HH:mm', 'H:mm', 'HH', 'H'], true) : null
        }
        onChange={handleChangeAbsolute}
        views={['hours', 'minutes', 'seconds']}
      />
    </LocalizationProvider>
  );
};
