import {
  assertUnreachable,
  DataType,
  FieldFilter,
  NumericComparator,
  StringComparator
} from '@aireframe/shared-types';

export const createFilter = (id: number, key: string, dataType: DataType): Array<FieldFilter> => {
  const baseFields = { id, key, liquidExpression: null };

  // Boolean works in the reverse way where all options are selected by default (due it being OR)
  // Boolean ids are negative to keep them at the top of the list
  switch (dataType) {
    case DataType.BOOLEAN:
      return [
        {
          ...baseFields,
          id: -1,
          value: { dataType, booleanValue: true }
        },
        {
          ...baseFields,
          id: -2,
          value: { dataType, booleanValue: false }
        },
        {
          ...baseFields,
          id: -3,
          value: { dataType, booleanValue: null }
        }
      ];
    case DataType.INTEGER:
      return [
        {
          ...baseFields,
          value: { dataType, integerValue: null },
          comparator: NumericComparator.Equals
        }
      ];
    case DataType.DECIMAL:
      return [
        {
          ...baseFields,
          value: { dataType, decimalValue: null },
          comparator: NumericComparator.Equals
        }
      ];
    case DataType.DATE:
      return [
        {
          ...baseFields,
          value: { dataType, dateValue: null },
          comparator: NumericComparator.Equals
        }
      ];
    case DataType.TIME:
      return [
        {
          ...baseFields,
          value: { dataType, timeValue: null },
          comparator: NumericComparator.Equals
        }
      ];
    case DataType.DATETIME:
      return [
        {
          ...baseFields,
          value: { dataType, dateTimeValue: null },
          comparator: NumericComparator.Equals
        }
      ];
    case DataType.STRING:
      return [
        {
          ...baseFields,
          value: { dataType, stringValue: null },
          comparator: StringComparator.Contains
        }
      ];
    case DataType.USERIDENTIFIER:
      return [
        {
          ...baseFields,
          value: { dataType: DataType.USERIDENTIFIER, userIdentifierValue: null }
        }
      ];
    default:
      assertUnreachable(dataType);
  }
};
