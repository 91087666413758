import { Popover, TextField } from '@mui/material';
import { Suspense, useEffect, useRef, useState } from 'react';
import { LiquidEditorProps } from './MonacoLiquidEditor';
import { lazyRetry } from '../LazyRetry';
import Loading from '../Loading/Loading';

const MonacoLiquidEditor = lazyRetry(() => import('./MonacoLiquidEditor'));

export const LiquidEditor = ({
  options,
  value,
  disabled,
  height = '20vh',
  width = '50vw',
  onChange,
  ...textFieldProps
}: LiquidEditorProps) => {
  const [isExpanded, setIsExpanded] = useState(false);

  const gridRef = useRef<HTMLElement>(null);
  const textFieldRef = useRef<HTMLDivElement>(null);
  const textFieldInputRef = useRef<HTMLInputElement>(null);
  const popoverRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (textFieldInputRef.current) {
      const onFocus = (e: FocusEvent) => {
        if (!e.relatedTarget) return;
        setIsExpanded(true);
      };

      const onMouseDown = (e: MouseEvent) => {
        if (!textFieldRef.current || !(e.target instanceof HTMLElement)) {
          return;
        }

        if (e.target === textFieldInputRef.current || textFieldRef.current.contains(e.target)) {
          setIsExpanded(true);
          return;
        }

        if (
          popoverRef.current &&
          !(e.target === popoverRef.current || popoverRef.current.contains(e.target))
        ) {
          setIsExpanded(false);
        }
      };

      const inputRefCurrent = textFieldInputRef.current;
      inputRefCurrent.addEventListener('focus', onFocus);
      document.addEventListener('mousedown', onMouseDown);

      return () => {
        inputRefCurrent.removeEventListener('focus', onFocus);
        document.removeEventListener('mousedown', onMouseDown);
      };
    }
  }, [textFieldInputRef]);

  useEffect(() => {
    if (isExpanded || value === undefined || value === '') return;
    textFieldInputRef.current?.blur();
  }, [isExpanded, value]);

  return (
    <span ref={gridRef}>
      <TextField
        {...textFieldProps}
        value={value ?? ''}
        inputRef={textFieldInputRef}
        ref={textFieldRef}
        disabled={disabled}
        onKeyDown={() => {
          return;
        }}
      />
      {isExpanded && (
        <Popover
          open
          anchorEl={gridRef.current}
          style={{ overflow: 'visible' }}
          anchorOrigin={{
            vertical: 'center',
            horizontal: 'center'
          }}
          transformOrigin={{
            vertical: 'center',
            horizontal: 'center'
          }}>
          <div ref={popoverRef}>
            <Suspense fallback={<Loading />}>
              <MonacoLiquidEditor
                options={options}
                value={value}
                height={height}
                width={width}
                onChange={onChange}
                disabled={disabled}
                onClose={() => setIsExpanded(false)}
              />
            </Suspense>
          </div>
        </Popover>
      )}
    </span>
  );
};
