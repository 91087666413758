import { styled, Typography, TypographyProps } from '@mui/material';
import * as React from 'react';

interface IProps extends Omit<TypographyProps, 'ref'> {
  fullscreen?: boolean;
  message?: string | string[];
}

const ErrorMessageContainer = styled('div', {
  shouldForwardProp: prop => prop !== 'fullscreen'
})<{ fullscreen: boolean }>(({ fullscreen }) => ({
  padding: 0,
  margin: 0,
  top: 0,
  left: 0,
  display: 'flex',
  alignItems: 'center',
  width: '100%',
  color: 'black',
  fontWeight: 800,
  position: fullscreen ? 'fixed' : undefined
}));

const ErrorMessage: React.FC<IProps> = ({
  fullscreen = false,
  message = 'Sorry, an error occurred',
  ...typographyProps
}) => {
  const messages = Array.isArray(message) ? message : [message];

  return (
    <ErrorMessageContainer fullscreen={fullscreen} data-testid="Error">
      <div style={{ width: '100%' }}>
        {messages.map((m, i) => (
          <Typography
            key={i}
            component="p"
            align="center"
            gutterBottom
            {...typographyProps}
            sx={{ color: theme => theme.palette.error.main, p: 1, ...typographyProps.sx }}>
            {m}
          </Typography>
        ))}
      </div>
    </ErrorMessageContainer>
  );
};

export default ErrorMessage;
