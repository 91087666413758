import { gql } from '@apollo/client';
import { InputModel } from './NestedModel';
import { ValueInput } from '../../api.gen';

export const RENDER_LIQUID_EXPRESSION_QUERY_NAME = 'renderLiquidExpression';
export const RENDER_LIQUID_EXPRESSION_QUERY = gql`
  query ${RENDER_LIQUID_EXPRESSION_QUERY_NAME}($expression: String!, $model: [KeyValuePairOfStringAndNestedKeyValuePairValueOfStringAndValueInput!]) {
    result: renderLiquidExpression(expression: $expression, model: $model)
  }
`;

export type RenderLiquidExpressionQueryVariables = {
  expression: string;
  model: InputModel<ValueInput> | null;
};

export type RenderLiquidExpressionQueryData = {
  result: string | null;
};
