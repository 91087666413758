import MonacoEditor, { loader } from '@monaco-editor/react';
import { isEqual } from 'lodash-es';
import * as monaco from 'monaco-editor';
import { useEffect, useRef, useState } from 'react';
import { LiquidCodeCompletionProvider, LiquidOptions } from './LiquidCodeCompletionProvider';
import { TextFieldProps } from '@mui/material';

loader.config({ monaco });
loader.init();

type MonacoEditorProps = {
  options?: LiquidOptions;
  value: string | undefined;
  onChange: (value: string | undefined) => void;
  height?: string;
  width?: string;
  disabled?: boolean;
};

export type LiquidEditorProps = Omit<
  TextFieldProps,
  'onChange' | 'value' | 'onFocus' | 'disabled'
> &
  MonacoEditorProps;

type MonacoLiquidEditorProps = MonacoEditorProps & {
  onClose?: () => void;
  onMount?: (editor: monaco.editor.IStandaloneCodeEditor) => void;
};

const MonacoLiquidEditor: React.FC<MonacoLiquidEditorProps> = ({
  options,
  value,
  disabled,
  height,
  width,
  onChange,
  onClose,
  onMount
}) => {
  const completionItemProviderDispose = useRef<monaco.IDisposable>();
  const [previousOptions, setPreviousOptions] = useState<LiquidOptions>();

  useEffect(() => {
    if (options && isEqual(options, previousOptions)) {
      return;
    } else if (options) {
      setPreviousOptions(options);
    }

    if (completionItemProviderDispose.current) {
      completionItemProviderDispose.current.dispose();
    }

    completionItemProviderDispose.current = monaco.languages.registerCompletionItemProvider(
      'liquid',
      new LiquidCodeCompletionProvider(options)
    );
  }, [options, previousOptions]);

  const onMountHandler = (editor: monaco.editor.IStandaloneCodeEditor) => {
    const position = new monaco.Position(1, 1);
    editor.setPosition(position);
    editor.focus();

    if (onClose) {
      editor.onKeyDown((e: monaco.IKeyboardEvent) => {
        if (e.keyCode === monaco.KeyCode.Escape) {
          onClose();
        }
      });
    }

    if (onMount) onMount(editor);
  };

  const handleEditorChange = (value: string | undefined) => {
    onChange(value);
  };

  useEffect(() => {
    return () => completionItemProviderDispose.current?.dispose();
  }, []);

  return (
    <>
      <MonacoEditor
        height={height ?? '20vh'}
        width={width ?? '50vh'}
        language="liquid"
        onChange={handleEditorChange}
        value={value}
        options={{
          minimap: {
            enabled: false
          },
          readOnly: disabled,
          fixedOverflowWidgets: true
        }}
        onMount={onMountHandler}
      />
    </>
  );
};

export default MonacoLiquidEditor;
