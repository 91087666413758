import { OidcUserClaims, OidcUser, FetchError, OidcUserProfile } from '@aireframe/shared-types';
import { jwtDecode } from 'jwt-decode';

const transformClaimsToUser = (claims: OidcUserClaims[]): OidcUser | null => {
  if (claims.length === 0) return null;
  return {
    id: (claims.find(item => item.type === 'sub')?.value as string) || '',
    name: (claims.find(item => item.type === 'name')?.value as string) || '',
    email: (claims.find(item => item.type === 'email')?.value as string) || '',
    tenantKey: (claims.find(item => item.type === 'tenant_key')?.value as string) || '',
    scopes: claims.filter(item => item.type === 'scope').map(item => item.value as string),
    logoutUrl: (claims.find(item => item.type === 'bff:logout_url')?.value as string) || '',
    session_expires_at: parseInt(
      (claims.find(item => item.type === 'bff:session_expires_in')?.value as string) || '0'
    ),
    profile: (claims.find(item => item.type === 'profile')?.value as OidcUserProfile) || {
      picture: ''
    },
    jobRole: (claims.find(item => item.type === 'job_role')?.value as string) || ''
  };
};

const authFetch = async (url: string) => {
  const response = await fetch(url, {
    credentials: 'same-origin',
    headers: {
      'X-CSRF': '1'
    }
  });
  if (response.status === 401 || response.status === 403) {
    throw new FetchError('Unauthorized', response.status);
  }
  if (!response.ok) {
    const errorData = await response.json();
    throw new FetchError(errorData.message || 'Network response was not ok.', response.status);
  }
  return response.json();
};

export const retrieveAccessToken = async (): Promise<string | null> => {
  const response = await authFetch('/bff/accesstoken');
  if (response) {
    return response.accessToken;
  }
  return null;
};

export const retrieveOidcUserClaims = async (path: string): Promise<OidcUser | null> => {
  const claims = await authFetch(path);

  if (!claims) {
    return null;
  }
  const user = transformClaimsToUser(claims);

  if (user !== null) {
    return user;
  }

  return null;
};

export function getSecondsUnitlExpiry(bearerToken: string): number;
export function getSecondsUnitlExpiry(bearerToken: null | undefined): null;
export function getSecondsUnitlExpiry(bearerToken: string | null | undefined) {
  if (!bearerToken) {
    return null;
  }

  const decoded = jwtDecode(bearerToken);
  if (!decoded.exp) {
    return null;
  }

  const unixTimestamp = Math.floor(new Date().getTime() / 1000);
  return decoded.exp - unixTimestamp;
}
